
import { Component, PropSync, Vue } from "vue-property-decorator";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import Str from "@/helper/Str";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: { DefaultForm },
})
export default class BaseData extends Vue {
  @PropSync("machine", { type: String, required: true })
  protected mMachine!: string;
}
