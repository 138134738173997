
import { Component, Vue } from "vue-property-decorator";
import Str from "../../helper/Str";
import BaseData from "@/components/modules/machines/create_assistant/steps/BaseData.vue";
import NetworkAndIp from "@/components/modules/machines/create_assistant/steps/NetworkAndIp.vue";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: { NetworkAndIp, BaseData },
})
export default class MachineCreateAssistant extends Vue {
  protected activeStep = 0;

  protected machine = "";
}
