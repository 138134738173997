
import { Component, PropSync, Vue } from "vue-property-decorator";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import Str from "@/helper/Str";
import NetworksSelect from "@/components/form_inputs/NetworksSelect.vue";
import { mixins } from "vue-class-component";
import { MachineStoreMixin } from "@/mixins/Stores/MachineStoreMixin";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: { NetworksSelect, DefaultForm },
})
export default class NetworkAndIp extends mixins(MachineStoreMixin) {
  @PropSync("machine", { type: String, required: true })
  protected mMachine!: string;

  protected network = "";
}
