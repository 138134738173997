import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ICustomer from "@/interfaces/ICustomer";
import IMachine from "@/interfaces/IMachine";

const SelectsModule = namespace("selects");

@Component({})
export class SelectsStoreMixin extends Vue {
  @SelectsModule.Action("loadMachines")
  protected loadSelectMachinesAction: any;

  @SelectsModule.Action("loadNetworks")
  protected loadSelectNetworksAction: any;

  /**
   * Getters
   */
  @SelectsModule.Getter("getMachines")
  protected getSelectMachines!: Array<IMachine>;

  @SelectsModule.Getter("isMachinesLoading")
  protected isSelectMachinesLoading!: boolean;

  @SelectsModule.Getter("getNetworks")
  protected getSelectNetworks!: Array<string>;

  @SelectsModule.Getter("isNetworksLoading")
  protected isSelectNetworksLoading!: boolean;

  /**
   * Mutations
   */
  @SelectsModule.Mutation("SET_MACHINES")
  protected setMachines: any;

  @SelectsModule.Mutation("SET_MACHINES_LOADING")
  protected setMachinesLoading: any;

  @SelectsModule.Mutation("SET_NETWORKS")
  protected setNetworks: any;

  @SelectsModule.Mutation("SET_MACHINES_LOADING")
  protected setNetworksLoading: any;
}
