
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import IProviderCompany from "@/interfaces/IProviderCompany";
import { ProviderCompanies } from "@/config/Consts";
import { mixins } from "vue-class-component";
import { SelectsStoreMixin } from "@/mixins/Stores/SelectsStoreMixin";

@Component({})
export default class NetworksSelect extends mixins(SelectsStoreMixin) {
  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  @Prop({ required: false, default: "" })
  protected placeholder!: string;

  protected get selectData(): Array<string> {
    return this.getSelectNetworks;
  }

  @VModel()
  protected selected!: number;

  created() {
    this.loadSelectNetworksAction();
  }
}
